/**
 * @name opPopupDetailEvenement
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupDetailEvenement', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupDetailEvenementControleur',
        ['$scope', '$modalInstance', '$q', '$modal', 'typePlanning', 'titre', 'ressource', 'evenement', 'fr.operis.moteurV4.composants.OpPopupAlerte',
            function (scope, modalInstance, $q, $modal, typePlanning, titre, ressource, evenement, opPopupAlerte) {
                scope.titre = titre;
                scope.evenementMaitre = evenement.maitre;
                scope.evenementOccupation = (typePlanning === "contact");
                scope.libRessource = ressource.libRes + " (" + ressource.typeRes + ")";
                scope.libEvenement = (!angular.isNullOrUndefined(evenement.refLigne)) ? evenement.refLigne + " " + evenement.libLigne : evenement.libLigne;
                scope.descEvenement = evenement.descLigne;

                // Lecture seule ?
                scope.lectureSeule = (angular.isNullOrUndefined(evenement.lectureSeule)) ? false : evenement.lectureSeule;
                if ((typePlanning === "recurrence") && !scope.lectureSeule) {
                    scope.lectureSeule = (!evenement.recurrente);
                }

                /*scope.dureeEvenement = "Du " + moment(evenement.debut).format("DD/MM/YYYY HH:mm");
                if (moment(evenement.fin).isValid()) {
                    scope.dureeEvenement += " au " + moment(evenement.fin).format("DD/MM/YYYY HH:mm");
                } else {
                    scope.dureeEvenement += " au " + moment(evenement.debut).format("DD/MM/YYYY HH:mm");
                }*/
                scope.dureeEvenement = "";
                if (!angular.isNullOrUndefined(evenement.fin)) {
                    if (moment(evenement.fin).isValid()) {
                        scope.dureeEvenement = "Du " + moment(evenement.debut).format("DD/MM/YYYY HH:mm");
                        scope.dureeEvenement += " au " + moment(evenement.fin).format("DD/MM/YYYY HH:mm");
                    } else {
                        scope.dureeEvenement = moment(evenement.debut).format("DD/MM/YYYY HH:mm");
                    }
                } else {
                    scope.dureeEvenement = moment(evenement.debut).format("DD/MM/YYYY HH:mm");
                }
				scope.dureeEvenement = scope.dureeEvenement.replace(/ 00:00/g, "");

                /**
                 * Afichage popup pour confirmation avant suppression
                 */
                scope.confirmationAvantSuppression = function () {
                    var deferred = $q.defer();

                    // Confirmation avant suppression
                    var msg = 'Supprimer "' + evenement.titleAgenda + '" ?';
                    var popupConfirmation = opPopupAlerte.creer('warning', msg, true, true);

                    popupConfirmation.result.then(function () {
                        deferred.resolve();
                    }, function () {
                        deferred.reject();
                    });

                    return deferred.promise;
                };

                // Supprimer l'évenement
                scope.supprimer = function () {
                    scope.confirmationAvantSuppression().then(function () {
                        modalInstance.close("supprimer");
                    });
                };

                // Fermer
                scope.fermer = function () {
                    modalInstance.close("fermer");
                };
            }
        ]
    );

