/**
 * @name OpPopupAjoutTache
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAjoutTache', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheControleur',
        ['$scope', '$modalInstance', 'fr.operis.moteurV4.communication.OperisWSServer', 'titre', 'premiereTache', 'vueFormulaire',
            function (scope, modalInstance, serveur, titre, premiereTache, vueFormulaire) {
                scope.titre = titre;
                scope.vueFormulaire = vueFormulaire;

                // Init objet évènement
                scope.tache = {};

                // Saisie complète ?
                scope.saisieNOK = function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHE)) {
                        return ((angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_OBJET.valeur) || (scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_OBJET.valeur.trim() === "")) ||
                        angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEDEBUT_PREV.valeur) ||
                        angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHE.ID_CONTACT.valeur));
                    } else {
                        return true;
                    }
                };

                // Ajouter la tâche
                scope.valider = function () {
                    scope.tache.idEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.ID_INTERVENTION.valeur;
                    scope.tache.refEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.REFERENCE_INTERVENTION.valeur;
                    scope.tache.adresseEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.INT_ADRESSECOMPLETE.valeur;
                    scope.tache.typeRessource = "Intervenant";
                    scope.tache.idRessource = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.ID_CONTACT.valeur;
                    scope.tache.libRessource = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.LIB_CONTACT.valeur;
                    scope.tache.qteRessource = 1;

                    var refObjet = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_OBJET.valeur;
                    scope.tache.libLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_OBJET.valeur;
                    scope.tache.descLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DESCRIPTION.valeur;

                    for (var i = 0; i < scope.vueFormulaire.listes.VL_TAC_OBJET.length; i++) {
                        if (scope.vueFormulaire.listes.VL_TAC_OBJET[i].TAC_OBJET_LIB.valeur === refObjet) {
                            scope.tache.refLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[i].TAC_OBJET_CODE.valeur;
                            scope.tache.libLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[i].TAC_OBJET_LIB.valeur;
                            break;
                        }
                    }

                    scope.tache.debut = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEDEBUT_PREV.valeur;
                    scope.tache.fin = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEFIN_PREV.valeur;

                    scope.tache.debutReel = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEDEBUT.valeur;
                    scope.tache.finReelle = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEFIN.valeur;

                    //scope.tache.heureDebut = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_HEUREDEBUT.valeur;
                    //scope.tache.heureFin = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_HEUREFIN.valeur;
                    scope.tache.dureeJours = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DUREEJOUR.valeur;
                    scope.tache.dureeHeures = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DUREEHEURE.valeur;
                    scope.tache.avancement = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_AVANCEMENT.valeur;

                    modalInstance.close(scope.tache);
                };

                // Annuler
                scope.fermer = function () {
                    modalInstance.close();
                };

                // Init données de l'écran
                scope.$watch(function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHE)) {
                        return scope.vueFormulaire.champs.VL_TAC_CREERTACHE.REFERENCE_INTERVENTION.valeur;
                    } else {
                        return null;
                    }
                }, function (newValue, oldValue) {
                    if (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue)) {
                        // Démarrer avec un formulaire vide
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_OBJET.valeur = null;

                        // Mantis 6216 Dupliquer la description de l'intervention dans la description de la tâche lors de la création
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DESCRIPTION.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.INT_OBJETDESCRIPTION.valeur;

                        // Mantis 6173 Reprendre les dates prévisionnelles
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEDEBUT_PREV.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.INT_DATEDEBUT_PREV.valeur;
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHE.TAC_DATEFIN_PREV.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.INT_DATEFIN_PREV.valeur;

                        // Mantis 6178 Reprendre l'intervenant si première tâche de l'intervention
                        if (premiereTache) {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHE.LIB_CONTACT.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.LIB_CONTACT.valeur;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHE.ID_CONTACT.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHE.INT_ID_INTERVENANT.valeur;
                        } else {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHE.ID_CONTACT.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHE.LIB_CONTACT.valeur = null;
                        }
                    }
                });
            }
        ]
    );

