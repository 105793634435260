/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

(function () {
    var x2js;

    angular.module('fr.operis.moteurV4.utils.XML', [])
        .service('fr.operis.moteurV4.utils.XML'
        , [
            function () {
                // Récupération de la librairie x2js si ce n'est pas encore fait
                if (angular.isNullOrUndefined(x2js)) {
                    x2js = window.x2js;
                    if (angular.isNullOrUndefined(x2js))
                        throw "Le service XML n'a pas pu s'initialiser. Vérifiez que la librairie 'x2js' est bien chargée !";

                    window.x2js = undefined;
                    //delete window.x2js;
                }

                /**
                 * Convertit un document XML en JSON
                 * @param {String|DomElement} aXML
                 * @returns {Object} L'objet javascript correspondant.
                 */
                this.toJson = function (aXML) {
                    if (typeof(aXML) === "string")
                        return x2js.xml_str2json(aXML);
                    return x2js.xml2json(aXML);
                };
            }
        ]
    );
})();