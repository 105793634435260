/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.directives.OpAutoFocus', [])
    .directive('opAutofocus', ['$timeout',
        function ($timeout) {
            return {
                scope: {trigger: '@opAutofocus'},
                link: function (scope, element) {
                    scope.$watch('trigger', function (value) {
                        if (value === "true") {
                            $timeout(function () {
                                element[0].focus();
                            });
                        }
                    });
                }
            };
        }
    ]);

