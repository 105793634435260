/**
 * @version 1.0
 * @copyright 2016 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Messagerie', [])
    //--- Utilisateur / Annuaire
    // Ecran de lecture sur la jointure utilisateur / annuaire
    .constant('fr.operis.moteurV4.utils.Messagerie.ECRAN_LECTURE_UTIL_ANNUAIRE', 'OperiaUtilisateurAnnuaire')
    // Champ identifiant de l'utilisateur pour l'annuaire
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_UTIL_ANNUAIRE', 'IDENT_UTILISATEUR')
    // Grille du résultat de lecture de l'annuaire
    .constant('fr.operis.moteurV4.utils.Messagerie.GRILLE_ANNUAIRE', 'VL_OP_UTIL_ANNUAIRE')
    // Champ identifiant de l'annuaire
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_UTIL', 'ID_ANNUAIRE')
    //--- Nombre de messages non lus
    // Ecran de lecture du nombre de messages non lus par destinataire
    .constant('fr.operis.moteurV4.utils.Messagerie.ECRAN_LECTURE_NB_MESS_NONLUS', 'OperisFrameworkLectureNbMessagesNonLus')
    // Champ identifiant de l'annuaire pour les messages non lus
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_NONLU', 'MESS_DEST_ID_ANNUAIRE')
    // Grille du résultat de lecture du nombre de messages
    .constant('fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_NB_NONLUS', 'VL_GES_MESS_NBNONLU')
    // Champ nombre de messages
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_MESS_NB_NONLUS', 'MESS_NB_NONLUS')
    //--- Messages non lus
    // Ecran de recherche des messages non lus
    .constant('fr.operis.moteurV4.utils.Messagerie.ECRAN_RECHERCHE_MESSAGE_NONLU', 'OperiaRechercheMessagesNonLus')
    // Champ identifiant de l'annuaire pour les destinataires
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_MESS_NONLU', 'MESS_DEST_ID_ANNUAIRE')
    // Grille du résultat de recherche des messages non lus
    .constant('fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_NONLU', 'VL_OP_MESS_NONLU')
    //--- Messages par entité
    // Ecran de recherche des messages par entité
    .constant('fr.operis.moteurV4.utils.Messagerie.ECRAN_RECHERCHE_MESSAGE_ENTITE', 'OperiaRechercheMessagesConfidentialite')
    // Champ identifiant de l'annuaire pour les destinataires
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_MESS_ENTITE', 'FILTRE_DESTINATAIRE')
    // Champ identifiant du module
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_MODULE_MESS_ENTITE', 'MESS_IDENT_MODULE')
    // Champ identifiant de l'entité
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ENTITE_MESS_ENTITE', 'FILTRE_ENTITE')
    // Grille du résultat de recherche des messages par entité
    .constant('fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_ENTITE', 'VL_OP_MESS_CONFID')
    //--- Etat des messages
    // Ecran d'écriture de l'état des messages par destinataire
    .constant('fr.operis.moteurV4.utils.Messagerie.ECRAN_ETAT_MESSAGE', 'OperisFrameworkEcritureMessageDestinataires')
    // Champ identifiant du message pour l'état
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_MESS_ETAT', 'ID_MESSAGE')
    // Champ identifiant de l'annuaire pour l'état des messages
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_ETAT', 'MESS_DEST_ID_ANNUAIRE')
    // Grille du résultat de lecture du nombre de messages
    .constant('fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_ETAT', 'GES_MESS_DESTINATAIRE')
    // Champ nombre de messages
    .constant('fr.operis.moteurV4.utils.Messagerie.CHAMP_MESS_ETAT', 'MESS_ETAT_NOTIF')
    .service('fr.operis.moteurV4.utils.Messagerie'
        , ['$q', '$http', 'fr.operis.moteurV4.communication.OperisWSServer'
            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE'
            , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS'
            , 'fr.operis.moteurV4.modele.Modele.SCOPE_CLE_PRIMAIRE'
            , 'fr.operis.moteurV4.utils.Conversion'
            , 'fr.operis.moteurV4.utils.Messagerie.ECRAN_LECTURE_UTIL_ANNUAIRE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_UTIL_ANNUAIRE'
            , 'fr.operis.moteurV4.utils.Messagerie.GRILLE_ANNUAIRE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_UTIL'
            , 'fr.operis.moteurV4.utils.Messagerie.ECRAN_LECTURE_NB_MESS_NONLUS'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_NONLU'
            , 'fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_NB_NONLUS'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_MESS_NB_NONLUS'
            , 'fr.operis.moteurV4.utils.Messagerie.ECRAN_RECHERCHE_MESSAGE_NONLU'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_MESS_NONLU'
            , 'fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_NONLU'
            , 'fr.operis.moteurV4.utils.Messagerie.ECRAN_RECHERCHE_MESSAGE_ENTITE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_MESS_ENTITE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_MODULE_MESS_ENTITE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ENTITE_MESS_ENTITE'
            , 'fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_ENTITE'
            , 'fr.operis.moteurV4.utils.Messagerie.ECRAN_ETAT_MESSAGE'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_MESS_ETAT'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_ID_ANNUAIRE_ETAT'
            , 'fr.operis.moteurV4.utils.Messagerie.GRILLE_MESS_ETAT'
            , 'fr.operis.moteurV4.utils.Messagerie.CHAMP_MESS_ETAT'
            , function ($q, $http, serveur
                , SEPARATEUR_INTERNE
                , SCOPE_DESCRIPTIONS
                , SCOPE_CLE_PRIMAIRE
                , conversion
                , ECRAN_LECTURE_UTIL_ANNUAIRE
                , CHAMP_ID_UTIL_ANNUAIRE
                , GRILLE_ANNUAIRE
                , CHAMP_ID_ANNUAIRE_UTIL
                , ECRAN_LECTURE_NB_MESS_NONLUS
                , CHAMP_ID_ANNUAIRE_NONLU
                , GRILLE_MESS_NB_NONLUS
                , CHAMP_MESS_NB_NONLUS
                , ECRAN_RECHERCHE_MESSAGE_NONLU
                , CHAMP_ID_ANNUAIRE_MESS_NONLU
                , GRILLE_MESS_NONLU
                , ECRAN_RECHERCHE_MESSAGE_ENTITE
                , CHAMP_ID_ANNUAIRE_MESS_ENTITE
                , CHAMP_ID_MODULE_MESS_ENTITE
                , CHAMP_ID_ENTITE_MESS_ENTITE
                , GRILLE_MESS_ENTITE
                , ECRAN_ETAT_MESSAGE
                , CHAMP_ID_MESS_ETAT
                , CHAMP_ID_ANNUAIRE_ETAT
                , GRILLE_MESS_ETAT
                , CHAMP_MESS_ETAT) {

                /**
                 * Lit en base le nombre de messages non lus pour l'utilisateur courant.
                 * @param {String} aModuleNom Le nom du module à partir duquel envoyer la requête
                 * @param {Object} aRetour L'object dans lequel écrire le nombre de messages.
                 */
                this.lectureNbMessagesNonLus = function (aModuleNom, aRetour) {
                    var scope = {};
                    var identifiants = {};
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur(aModuleNom)());
                    identifiants[CHAMP_ID_UTIL_ANNUAIRE] = idUtilisateur;

                    serveur.chargement(aModuleNom, scope, ECRAN_LECTURE_UTIL_ANNUAIRE, identifiants)
                        .then(function () {
                            var idAnnuaire = scope.champs[GRILLE_ANNUAIRE][CHAMP_ID_ANNUAIRE_UTIL].valeur;

                            if (idAnnuaire) {
                                identifiants[CHAMP_ID_ANNUAIRE_NONLU] = idAnnuaire;

                                serveur.chargement(aModuleNom, scope, ECRAN_LECTURE_NB_MESS_NONLUS, identifiants)
                                    .then(function () {
                                        aRetour.nbMessages = scope.champs[GRILLE_MESS_NB_NONLUS][CHAMP_MESS_NB_NONLUS].valeur;
                                    });
                            }
                        });
                };

                /**
                 * Recherche en base les messages de l'utilisateur courant.
                 * @param {String} aModuleNom Le nom du module à partir duquel envoyer la requête
                 * @returns {Array} La grille des messages
                 */
                this.rechercheMessagesUtilisateur = function (aModuleNom, aRetour) {
                    var scope = {};
                    var identifiants = {};
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur(aModuleNom)());
                    identifiants[CHAMP_ID_UTIL_ANNUAIRE] = idUtilisateur;

                    serveur.chargement(aModuleNom, scope, ECRAN_LECTURE_UTIL_ANNUAIRE, identifiants)
                        .then(function () {
                            var idAnnuaire = scope.champs[GRILLE_ANNUAIRE][CHAMP_ID_ANNUAIRE_UTIL].valeur;

                            if (idAnnuaire) {
                                serveur.chargement(aModuleNom, scope, ECRAN_RECHERCHE_MESSAGE_NONLU, null)
                                    .then(function () {
                                        scope.champs[GRILLE_MESS_NONLU][CHAMP_ID_ANNUAIRE_MESS_NONLU].valeur = idAnnuaire;
                                        scope.recherche[ECRAN_RECHERCHE_MESSAGE_NONLU](1000)
                                            .then(function () {
                                                aRetour.descriptionRechercheMessage = scope.descriptions[GRILLE_MESS_NONLU];
                                                aRetour.donneesRechercheMessage = scope.grilles[GRILLE_MESS_NONLU];
                                            });
                                    });
                            }
                        });
                };

                /**
                 * Recherche en base les messages des entités sélectionnées.
                 * @param {String} aModuleNom Le nom du module à partir duquel envoyer la requête
                 * @param {} aOnglet
                 * @param {} aSelection
                 * @returns {Array} La grille des messages
                 */
                this.rechercheMessagesEntites = function (aModuleNom, aOnglet, aSelection, aRetour) {
                    var scope = {};
                    var identifiants = {};
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur(aModuleNom)());
                    identifiants[CHAMP_ID_UTIL_ANNUAIRE] = idUtilisateur;

                    serveur.chargement(aModuleNom, scope, ECRAN_LECTURE_UTIL_ANNUAIRE, identifiants)
                        .then(function () {
                            var idAnnuaire = scope.champs[GRILLE_ANNUAIRE][CHAMP_ID_ANNUAIRE_UTIL].valeur;

                            if (idAnnuaire) {
                                serveur.chargement(aModuleNom, scope, ECRAN_RECHERCHE_MESSAGE_ENTITE, null)
                                    .then(function () {
                                        var critereIdAnnuaire = idAnnuaire + SEPARATEUR_INTERNE + "-1";
                                        var critereIdModule = Number(serveur.getIdModule(aOnglet.moduleNom));
                                        var critereIdEntite = "";

                                        for (var i = 0; i < aSelection.length; i++){
                                            var entite = aSelection[i];

                                            for (var champ in entite) {
                                                if (entite[champ][SCOPE_DESCRIPTIONS][SCOPE_CLE_PRIMAIRE]) {
                                                    critereIdEntite += entite[champ].valeur + SEPARATEUR_INTERNE;
                                                    break;
                                                }
                                            }
                                        }

                                        if(critereIdEntite.length > 0)
                                            critereIdEntite = critereIdEntite.slice(0, -1);

                                        scope.champs[GRILLE_MESS_ENTITE][CHAMP_ID_ANNUAIRE_MESS_ENTITE].valeur = critereIdAnnuaire;
                                        scope.champs[GRILLE_MESS_ENTITE][CHAMP_ID_MODULE_MESS_ENTITE].valeur = critereIdModule;
                                        scope.champs[GRILLE_MESS_ENTITE][CHAMP_ID_ENTITE_MESS_ENTITE].valeur = critereIdEntite;
                                        scope.recherche[ECRAN_RECHERCHE_MESSAGE_ENTITE](1000)
                                            .then(function () {
                                                aRetour.descriptionRechercheMessage = scope.descriptions[GRILLE_MESS_ENTITE];
                                                aRetour.donneesRechercheMessage = scope.grilles[GRILLE_MESS_ENTITE];
                                            });
                                    });
                            }
                        });
                };

                /**
                 * Met à jour en base l'état du message.
                 * @param {String} aModuleNom Le nom du module à partir duquel envoyer la requête
                 * @param {String} aIdMessage L'identifiant du message dont l'état doit être updaté
                 * @param {String} aEtat L'état du message
                 */
                this.etatMessage = function (aModuleNom, aIdMessage, aEtat) {
                    var deferred = $q.defer();
                    var scope = {};
                    var identifiants = {};
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur(aModuleNom)());
                    identifiants[CHAMP_ID_UTIL_ANNUAIRE] = idUtilisateur;

                    serveur.chargement(aModuleNom, scope, ECRAN_LECTURE_UTIL_ANNUAIRE, identifiants)
                        .then(function () {
                            var idAnnuaire = scope.champs[GRILLE_ANNUAIRE][CHAMP_ID_ANNUAIRE_UTIL].valeur;

                            if (idAnnuaire) {
                                identifiants = {};
                                identifiants[CHAMP_ID_MESS_ETAT] = aIdMessage;
                                identifiants[CHAMP_ID_ANNUAIRE_ETAT] = idAnnuaire;

                                serveur.chargement(aModuleNom, scope, ECRAN_ETAT_MESSAGE, identifiants)
                                    .then(function () {
                                        scope.champs[GRILLE_MESS_ETAT][CHAMP_MESS_ETAT].valeur = aEtat;
                                        scope.sauvegarde[ECRAN_ETAT_MESSAGE](1000)
                                            .then(function () {
                                                deferred.resolve();
                                            });
                                    });
                            }
                        });
                    return deferred.promise;
                };

                /**
                 * Envoie un message (notification, mail, sms, ...)
                 * @param {String} aDestinataires Liste des destinataires du message séparé par des ;
                 * @param {String} aObjet L'objet du message
                 * @param {String} aDescription Le corps du message
                 * @param {String} aIdModule Le module auquel est associé le message
                 * @param {String} aIdEntite L'entité à laquelle est associée le message
                 * @param {String} aNature La nature du message
                 * @param {String} aPriorite La priorité du message
                 * @param {String} aConfidentialite La confidentialité du message
                 * @param {String} aDocuments La liste des documents
                 */
                this.envoieMessage = function (aDestinataires, aObjet, aDescription, aIdModule, aIdEntite, aNature, aPriorite, aConfidentialite, aDocuments) {
                    var deferred = $q.defer();

                    var scope = {};
                    var identifiants = {};
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur('Operia')());

                    var urlMessagerie = window.location.protocol + '//' + window.location.hostname + ':' +
                    window.location.port + '/' + window.location.pathname.split('/')[1] + '/Messagerie?' +
                    'idSession=' + serveur.getIdSession('Operia') + '&' + //
                    'message={"DESTINATAIRES":"' + aDestinataires +
                        '","DATECREATION":"' + conversion.versTexte(new Date(), 'DATE') +
                        '","ID_EXPEDITEUR":"' + idUtilisateur +
                        '","SUJET":"' + aObjet +
                        '","DONNEES":"' + aDescription +
                        '","ID_MODULE":"' + aIdModule +
                        '","ID_ENTITE":"' + aIdEntite;

                    if(!angular.isNullOrUndefined(aNature)) {
                        urlMessagerie += '","NATURE":"' + aNature;
                    }

                    if(!angular.isNullOrUndefined(aPriorite)) {
                        urlMessagerie += '","PRIORITE":"' + aPriorite;
                    }

                    if(!angular.isNullOrUndefined(aConfidentialite)) {
                        urlMessagerie += '","CONFIDENTIALITE":"' + aConfidentialite;
                    }

                    if(!angular.isNullOrUndefined(aDocuments)) {
                        urlMessagerie += '","DOCUMENTS":"' + aDocuments;
                    }

                    urlMessagerie += '"}';

                    $http({
                        method: 'GET',
                        url: urlMessagerie
                    }).then(function successCallback(response) {
                        deferred.resolve(response);
                    }, function errorCallback(response) {
                        deferred.reject(response);
                    });

					return deferred.promise;
                };
            }
        ]
    );
