/**
 * @name OpPopupAfficherRessources
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAfficherRessources', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAfficherRessourcesControleur',
        ['$scope', '$modalInstance', 'titre', 'listeRessources', 'listeRessourcesAffectees', 'filtreRessources',
            'fr.operis.moteurV4.utils.ModuleInfos',
            function (scope, modalInstance, titre, listeRessources, listeRessourcesAffectees, filtreRessources, moduleInfos) {
                scope.titre = titre;
                scope.listeRessources = listeRessources;
                scope.listeRessourcesAffectees = listeRessourcesAffectees;
                scope.filtreRessources = filtreRessources;
                scope.nbreSelect = 0;
                scope.nbreVisible = 0;

                scope.couleurPrimaire = moduleInfos.getInfo("Intervention", "MOD_COULEURPRIMAIRE");
                scope.couleurSecondaire = moduleInfos.getInfo("Intervention", "MOD_COULEURSECONDAIRE");

                if (scope.filtreRessources.typeRessource === "Matériel") {
                    scope.msgLancement = ((scope.filtreRessources.libRessource.trim() === "") && (scope.filtreRessources.typeMateriel.trim() === ""));
                } else {
                    scope.msgLancement = ((scope.filtreRessources.nomEquipe.trim() === "") && (scope.filtreRessources.libRessource.trim() === "") && (scope.filtreRessources.competence.trim() === ""));
                }

                function majNbreSelectEtVisible () {
                    var nbreSelect = 0;
                    var nbreVisible = 0;

                    for (var i = 0; i < scope.listeRessources.length; i++) {
                        if (scope.listeRessources[i].checked && !scope.listeRessources[i].dejaAffichee) {
                            nbreSelect = nbreSelect + 1;
                        }

                        if (scope.listeRessources[i].visible && !scope.listeRessources[i].dejaAffichee) {
                            nbreVisible = nbreVisible + 1;
                        }
                    }

                    scope.nbreSelect = nbreSelect;
                    scope.nbreVisible = nbreVisible;
                }

                function majNbreRessourceVisibleParType () {
                    if (!angular.isNullOrUndefined(scope.nbreRessourceParType)) {
                        var i, nb;

                        for (i = 0; i < scope.filtreRessources.listeTypeRessource.length; i++) {
                            scope.nbreRessourceParType[scope.filtreRessources.listeTypeRessource[i].lib] = 0;
                        }

                        for (i = 0; i < scope.listeRessources.length; i++) {
                            if (scope.listeRessources[i].visible && !scope.listeRessources[i].dejaAffichee) {
                                nb = scope.nbreRessourceParType[scope.listeRessources[i].typeRessource];
                                scope.nbreRessourceParType[scope.listeRessources[i].typeRessource] = nb + 1;
                            }
                        }
                    }
                }

                function majNbreRessourceVisibleParGroupe () {
                    if (!angular.isNullOrUndefined(scope.nbreRessourceParGroupe)) {
                        var i, nb;

                        for (i = 0; i < scope.filtreRessources.groupes.length; i++) {
                            scope.nbreRessourceParGroupe[scope.filtreRessources.groupes[i].libGroupe] = 0;
                        }

                        for (i = 0; i < scope.listeRessources.length; i++) {
                            if (scope.listeRessources[i].visible && !scope.listeRessources[i].dejaAffichee) {
                                nb = scope.nbreRessourceParGroupe[scope.listeRessources[i].equipe];
                                scope.nbreRessourceParGroupe[scope.listeRessources[i].equipe] = nb + 1;
                            }
                        }
                    }
                }

                function filtrerListeRessources (aucune) {
                    var listeValeur, filtreValeur;

                    for (var i = 0; i < scope.listeRessources.length; i++) {
                        scope.listeRessources[i].visible = aucune ? false : true;

                        // Type de ressource
                        if (scope.listeRessources[i].visible && (scope.filtreRessources.typeRessource.trim() !== "")) {
                            // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                            listeValeur = scope.listeRessources[i].typeRessource.toUpperCase();
                            listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                            filtreValeur = scope.filtreRessources.typeRessource.trim().toUpperCase();
                            filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                            scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                        }

                        if (filtreRessources.typeRessource === "Individu") {
                            // Statut
                            if (scope.listeRessources[i].visible && !angular.isNullOrUndefined(scope.listeRessources[i].statutRessource)) {
                                scope.listeRessources[i].visible = (scope.listeRessources[i].statutRessource !== "Inactif");
                            }

                            // Equipe
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.nomEquipe.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].equipe.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.nomEquipe.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }

                            // Libellé de ressource
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.libRessource.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].libRessource.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.libRessource.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }

                            // Compétence
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.competence.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].competence.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.competence.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }
                        } else if (filtreRessources.typeRessource === "Equipe") {
                            // Statut
                            if (scope.listeRessources[i].visible && !angular.isNullOrUndefined(scope.listeRessources[i].statutRessource)) {
                                scope.listeRessources[i].visible = (scope.listeRessources[i].statutRessource !== "Inactif");
                            }

                            // Libellé de ressource
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.nomEquipe.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].libRessource.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.nomEquipe.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }

                            // Compétence
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.competence.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].competence.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.competence.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }
                        } else if (filtreRessources.typeRessource === "Matériel") {
                            // Statut
                            if (scope.listeRessources[i].visible && !angular.isNullOrUndefined(scope.listeRessources[i].statutRessource)) {
                                scope.listeRessources[i].visible = (scope.listeRessources[i].statutRessource !== "Inactif");
                            }

                            // Libellé de ressource
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.libRessource.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].libRessource.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.libRessource.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }

                            // Type de matériel
                            if (scope.listeRessources[i].visible && (scope.filtreRessources.typeMateriel.trim() !== "")) {
                                // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                                listeValeur = scope.listeRessources[i].competence.toUpperCase();
                                listeValeur = listeValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                filtreValeur = scope.filtreRessources.typeMateriel.trim().toUpperCase();
                                filtreValeur = filtreValeur.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                                scope.listeRessources[i].visible = (listeValeur.indexOf(filtreValeur) !== -1);
                            }
                        }
                    }

                    majNbreSelectEtVisible();
                    majNbreRessourceVisibleParType();
                    majNbreRessourceVisibleParGroupe();
                    scope.msgLancement =  aucune ? true : false;
                }

                scope.toutes = function () {
                    for (var i = 0; i < scope.listeRessources.length; i++) {
                        if (scope.listeRessources[i].visible && !scope.listeRessources[i].dejaAffichee) {
                            scope.listeRessources[i].checked = true;
                        }
                    }

                    majNbreSelectEtVisible();
                };

                scope.aucune = function () {
                    for (var i = 0; i < scope.listeRessources.length; i++) {
                        if ((scope.listeRessources[i].existeEvent === false) &&
                            (scope.listeRessources[i].visible)  &&
                            !scope.listeRessources[i].dejaAffichee) {
                            scope.listeRessources[i].checked = false;
                        }
                    }

                    majNbreSelectEtVisible();
                };

                scope.rechercherToutes = function () {
                    filtrerListeRessources(false);
                };

                scope.clickCheckSelect = function () {
                    majNbreSelectEtVisible();
                };

                scope.clickCheckRecherche = function () {
                    majNbreSelectEtVisible();
                };

                scope.nbreTypeRessource = function(typeRessource) {
                    if (angular.isNullOrUndefined(scope.nbreRessourceParType[typeRessource])) {
                       return 0;
                    } else {
                        return scope.nbreRessourceParType[typeRessource];
                    }
                };

                scope.nbreGroupeRessource = function(typeRessource) {
                    if (angular.isNullOrUndefined(scope.nbreRessourceParGroupe[typeRessource])) {
                        return 0;
                    } else {
                        return scope.nbreRessourceParGroupe[typeRessource];
                    }
                };

                scope.valider = function () {
                    var selection = [];

                    for (var i = 0; i < scope.listeRessources.length; i++) {
                        if (scope.listeRessources[i].checked) {
                            selection.push(scope.listeRessources[i]);
                        }
                    }

                    modalInstance.close(selection);
                };

                scope.fermer = function () {
                    modalInstance.close();
                };

                scope.$watch('filtreRessources.typeRessource', function (newValue, oldValue) {
                    if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                        filtrerListeRessources(false);
                    }
                });

                scope.$watch('filtreRessources.nomEquipe', function (newValue, oldValue) {
                    if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                        filtrerListeRessources(false);
                    }
                });

                scope.$watch('filtreRessources.libRessource', function (newValue, oldValue) {
                    if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                        filtrerListeRessources(false);
                    }
                });

                scope.$watch('filtreRessources.competence', function (newValue, oldValue) {
                    if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                        filtrerListeRessources(false);
                    }
                });

                scope.$watch('filtreRessources.typeMateriel', function (newValue, oldValue) {
                    if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                        filtrerListeRessources(false);
                    }
                });

                // Init attributs pour la gestion de la sélection des ressources
                for (var i = 0; i < scope.listeRessources.length; i++) {
                    // Statut de la ressource (affectée ou non au planning et avec ou sans événements)
                    var dejaAffichee = false;
                    var existeEvent = false;
                    var existeEventStyle = "";

                    for (var j = 0; j < scope.listeRessourcesAffectees.length; j++) {
                        if ((scope.listeRessources[i].id === scope.listeRessourcesAffectees[j].id) &&
                            (scope.listeRessources[i].typeRessource === scope.listeRessourcesAffectees[j].typeRessource)) {
                            dejaAffichee = true;

                            if (scope.listeRessourcesAffectees[j].existeEvent) {
                                existeEvent = true;
                                existeEventStyle = "{'font-style':'italic','color':'#A0A0A0'}";
                            }

                            break;
                        }
                    }

                    scope.listeRessources[i].dejaAffichee = dejaAffichee;
                    scope.listeRessources[i].checked = false;
                    scope.listeRessources[i].visible = false;
                    scope.listeRessources[i].existeEvent = existeEvent;
                    scope.listeRessources[i].existeEventStyle = existeEventStyle;

                    // Init nombre de ressources sélectionnées
                    if (scope.listeRessources[i].checked) {
                        scope.nbreSelect = scope.nbreSelect + 1;
                    }

                    // Init nombre de ressources par type
                    if (angular.isNullOrUndefined(scope.nbreRessourceParType)) {
                        scope.nbreRessourceParType = {};

                        for (j = 0; j < scope.filtreRessources.listeTypeRessource.length; j++) {
                            scope.nbreRessourceParType[scope.filtreRessources.listeTypeRessource[j].lib] = 0;
                        }
                    }

                    // Init nombre de ressources par groupe
                    if (angular.isNullOrUndefined(scope.nbreRessourceParGroupe)) {
                        scope.nbreRessourceParGroupe = {};

                        for (j = 0; j < scope.filtreRessources.groupes.length; j++) {
                            scope.nbreRessourceParGroupe[scope.filtreRessources.groupes[j].libGroupe] = 0;
                        }
                    }

                    var nb = scope.nbreRessourceParGroupe[scope.listeRessources[i].equipe];
                    scope.nbreRessourceParGroupe[scope.listeRessources[i].equipe] = nb + 1;
                }

                // Si message de lancement non affiché alors lancer la recherche
                // Mantis 6175 : Lancer la recherche dès l'ouverture
                //if (!scope.msgLancement) {
                    filtrerListeRessources(false);
                //}
            }
        ]
    );

