// angular.module('operiaChat.chat', []);

angular.module('operiaChat.chat')
    /**
     * Provider to configure operiaChat (url http, ws, turn, stun)
     */
    .provider('operiaConfig', function () {
        var urlWs;
        var urlHttp;
        var isMobile;
        var stunUrl;
        var turnUrl;
        var turnUsername;
        var turnCredential;
        var affichage;
        return {
            setUrlWs: function (value) {
                urlWs = value;
            },
            setUrlHttp: function (value) {
                urlHttp = value;
            },
            setIsMobile: function (value) {
                isMobile = value;
            },
            setStunUrl: function (value) {
                stunUrl = value;
            },
            setTurnUrl: function (value) {
                turnUrl = value;
            },
            setTurnUsername: function (value) {
                turnUsername = value;
            },
            setTurnCredential: function (value) {
                turnCredential = value;
            },
            $get: function () {
                return {
                    urlWs: urlWs,
                    urlHttp: urlHttp,
                    isMobile: isMobile,
                    stunUrl: stunUrl,
                    turnUrl: turnUrl,
                    turnUsername: turnUsername,
                    turnCredential: turnCredential,
                    affichage: affichage
                };
            }
        };
    });

