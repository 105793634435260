/**
 * @version 1.0c
 * @copyright 2019 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpConnexionPortail', [
    'ui.bootstrap',
    'fr.operis.moteurV4.directives.OpSimpleFormat',
    'fr.operis.moteurV4.communication.OperisWSServer',
    'fr.operis.moteurV4.directives.OpAutoFocus'])
    .controller('fr.operis.moteurV4.composants.OpConnexionPortailControleur',
    ['$scope', '$modalInstance', '$window', '$sce',
     'fr.operis.moteurV4.utils.ConnexionPortail',
     'fr.operis.moteurV4.utils.Cookie',
     'fr.operis.moteurV4.utils.Config',
     'fr.operis.moteurV4.utils.Erreur',
     'fr.operis.moteurV4.communication.OperisWSServer',
     'utilisateur', 'erreurConnexion',
     'fr.operis.moteurV4.utils.Erreur.PORTAILS_DESCRIPTION_INVALIDE',
     'fr.operis.moteurV4.utils.Erreur.PORTAIL_DESCRIPTION_INVALIDE',
     'fr.operis.moteurV4.utils.Erreur.PORTAIL_UNKNOWN',
     'fr.operis.moteurV4.utils.Erreur.PORTAIL_AUTHORIZE_ERROR',
     'fr.operis.moteurV4.utils.Erreur.PORTAIL_UNVALID_STATE',
     'fr.operis.moteurV4.utils.Erreur.PORTAIL_EMPTY_ANSWER',
     'fr.operis.moteurV4.utils.Config.cookie.CGU',
     'fr.operis.moteurV4.utils.Config.cookie.LOGOUT_STATE',
    function ($scope, $modalInstance, $window, $sce,
            connexionPortail, cookie, config, erreur, operisWSServer,
            utilisateur, erreurConnexion,
            PORTAILS_DESCRIPTION_INVALIDE, PORTAIL_DESCRIPTION_INVALIDE,
            PORTAIL_UNKNOWN, PORTAIL_AUTHORIZE_ERROR, PORTAIL_UNVALID_STATE,PORTAIL_EMPTY_ANSWER,
            CGU, LOGOUT_STATE) {
        // Connexion Framework
        $scope.utilisateur = utilisateur;
        $scope.motdepasse = "";
        $scope.utilisateurModifiable = angular.isNullOrUndefined(utilisateur);
        $scope.erreursParCode = {};
        $scope.sessionsFermees = false;
        // Connexion Portail
        $scope.portailDisplay = config.$config.portail.display;
        $scope.portailOnly = config.$config.portail.only;
        $scope.portailCreaCompte = config.$config.portail.creaCompte;
        $scope.portails = null;
        $scope.portailsDescription = false;
        $scope.iframesrc = "";
        
        $scope.close = function () {
            $modalInstance.close();
        }
        
        /********************
        * Connexion Framework
        *********************/
        if (!angular.isNullOrUndefined(erreurConnexion)) {
            if (!angular.isNullOrUndefined($scope.erreursParCode[erreurConnexion.codeServeur]))
                $scope.erreursParCode[erreurConnexion.codeServeur] = [erreurConnexion];
            else
                $scope.erreursParCode[erreurConnexion.codeServeur].push(erreurConnexion);
        }
        
        $scope.pressePapier = function (erreur) {
            $window.prompt("Copier le texte : Ctrl+C, Entrée", angular.toJson(erreur));
        };
        
        $scope.valider = function () {
            return operisWSServer.connexionComplete($scope.utilisateur, $scope.motdepasse).then(
                function () {
                    $modalInstance.close();
                },
                function (erreurs) {
                    for (var i = 0; i < erreurs.length; i++) {
                        if ($scope.erreursParCode.hasOwnProperty(erreurs[i].codeServeur))
                            $scope.erreursParCode[erreurs[i].codeServeur].push(erreurs[i]);
                        else
                        $scope.erreursParCode[erreurs[i].codeServeur] = [erreurs[i]];
                    }

                    // Session déjà connectée...
                    var erreursSession = $scope.erreursParCode['01007'];
                    if ( erreursSession && erreursSession.length > 0){
                        var reponse = confirm("Voulez-vous déconnecter la session en cours et tenter une reconnexion ?");
                        if ( reponse ){
                            return $scope.fermer().then($scope.valider);
                        }
                    }
                });
        };
        
        $scope.fermer = function () {
            return operisWSServer.deconnexionComplete($scope.utilisateur, $scope.motdepasse).then(
                function () {
                    $scope.erreursParCode['01007'] = [];
                    $scope.sessionsFermees = true;
                },
                function (erreur) {
                    if (!angular.isNullOrUndefined($scope.erreursParCode[erreur.codeServeur]))
                        $scope.erreursParCode[erreur.codeServeur] = [erreur];
                    else
                        $scope.erreursParCode[erreur.codeServeur].push(erreur);
                });
        };
        
        /********************
        * Gestion des erreurs
        *********************/
        function _pushErreur(erreur) {
            if (angular.isNullOrUndefined($scope.erreursParCode[erreur.codeServeur]))
                $scope.erreursParCode[erreur.codeServeur] = [erreur];
            else
                $scope.erreursParCode[erreur.codeServeur].push(erreur);
        }
            
        /**********************
        * Nettoyage des cookies
        ***********************/
        function _cleanupCookies() {
            cookie.remove("portail");
            cookie.remove("portailState");
            cookie.remove("portailIframe");
            cookie.remove("portailReconnexion");
            cookie.remove("rapprochement");
        }
        
        /******************
        * Gestion de compte
        *******************/
        var _gestionCompteUrl = "./OperisCreateAccount.html?nameApp="
            + $window.APPLICATION;
        
        var _currentCGU = cookie.get(CGU);
        if(_currentCGU && _currentCGU.length > 0) {
            _gestionCompteUrl += "&cgu=" + $window.encodeURIComponent(_currentCGU)
                + "&successCallback=" + $window.encodeURIComponent(
                connexionPortail.extractUrlPath() + "?" + connexionPortail.extractOriginalParams());
        }
        
        var _creationCompteUrl = _gestionCompteUrl + "&methode=creation";
        var _recoverCompteUrl = _gestionCompteUrl + "&methode=regeneration";
        
        $scope.creationCompte = function () {
            // Si FranceConnect est un portail configuré et activé,
            // on charge sa description pour l'envoyer à la page de création de compte
            if ($scope.portails && $scope.portails["FranceConnect"] && $scope.portails["FranceConnect"].activated) {
                operisWSServer.descriptionPortailComplete(
                    "FranceConnect", $window.encodeURIComponent(
                        connexionPortail.extractUrlPath()), connexionPortail.encodedStateParams())
                .then(function (result) {
                    cookie.set("portail", result.name);
                    cookie.set("portailDataField", result.dataField);
                    // Traitement du state
                    var state = Math.random();
                    result.url_connection += state;
                    cookie.set("portailState", state);
                    // Traitement du clientId
                    result.url_connection = result.url_connection.replace("{clientId}", result.clientId);
                    // Appel de l'authentification
                    $window.location = _creationCompteUrl + "&portails=FranceConnect&portailRedirect=" + $window.encodeURIComponent(result.url_connection);
                })
                .catch(function (err) {
                    _pushErreur(erreur.creer(PORTAIL_DESCRIPTION_INVALIDE, null, portail, err));
                });
            } else {
                $window.location = _creationCompteUrl;
            }
        };
        
        $scope.recoverCompte = function () {
            return _recoverCompteUrl;
        };
        
        /******************
        * Connexion Portail
        *******************/
        $scope.isVisible = function (portail) {
            return $scope.portails != null 
                    && $scope.portails[portail] != undefined 
                    && $scope.portails[portail] != null;
        };
        
        $scope.isDisabled = function (portail) {
            return !$scope.isVisible(portail) || !$scope.portails[portail].activated;
        };
        
        $scope.connexionPortail = function (portail, iframe) {
            return operisWSServer.descriptionPortailComplete(
                portail, $window.encodeURIComponent(
                    connexionPortail.extractUrlPath()), connexionPortail.encodedStateParams())
            .then(function (result) {
                cookie.set("portail", result.name);
                // Traitement du state
                var state = Math.random();
                result.url_connection += state;
                cookie.set("portailState", state);
                // Traitement du clientId
                result.url_connection = result.url_connection.replace("{clientId}", result.clientId);
                // Appel de l'authentification
                if (iframe) {
                    
                    function cleanup(timeout) {
                        $window.removeEventListener("message", message, false);
                        if(handle) {
                            $window.clearTimeout(handle);
                        }
                        handle = null;
                        $scope.iframesrc = "";
                        
                        if (timeout) {
                            // Si timeout, connexion directe
                            cookie.remove("portailIframe");
                            cookie.set("portailIframeError", true);
                            $scope.connexionPortail(Object.keys($scope.portails)[0], false);
                        }
                    }
                    
                    function message(e) {
                        if (e.origin == $window.location.protocol + "//" + $window.location.host) {
                            cleanup();
                            if (e.data === "iframeError") {
                                // Si vérification en erreur, connexion directe
                                cookie.remove("portailIframe");
                                cookie.set("portailIframeError", true);
                                $scope.connexionPortail(Object.keys($scope.portails)[0], false);
                            } else {
                                // déclenchement de la connexion via portail
                                _validateConnexion(e.data);
                            }
                        }
                    }
                    
                    cookie.set("portailIframe", true);
                    var handle = $window.setTimeout(function() {
                        cleanup(true);
                    }, 5000);
                    $window.addEventListener("message", message, false);
                    $scope.iframesrc = $sce.trustAsResourceUrl(result.url_connection + "&prompt=none");
                } else {
                    $window.location = result.url_connection;
                }
            })
            .catch(function (err) {
                _pushErreur(erreur.creer(PORTAIL_DESCRIPTION_INVALIDE, null, portail, err));
            });
        };
        
        function _validateConnexion (data) {
            var reconnexion = true; // On kill d'office les sessions FWK précédentes (sinon, cookie.get("portailReconnexion"))
            return operisWSServer.validateConnexionPortailComplete(
                cookie.get("portail"),
                $window.encodeURIComponent(connexionPortail.extractUrlPath()),
                connexionPortail.encodedStateParams(),
                data,
                reconnexion,
                cookie.get("rapprochement"))
            .then(function () {
                // Nettoyage des erreurs de session déjà connectée
                if(cookie.get("portailReconnexion")) {
                    $scope.erreursParCode['01007'] = [];
                    $scope.sessionsFermees = true;
                }
                // Nettoyage des cookies
                _cleanupCookies();
                
                if (cookie.get("portailIframeError") || !config.$config.portail.auto) {
                    cookie.remove("portailIframeError");
                    // Supprime les paramètres ajoutés lors de la connexion directe par portail
                    var href = connexionPortail.extractUrlPath() + "?" + connexionPortail.extractOriginalParams();
					$window.location.href = href;
                }
                $modalInstance.close();
            },
            function (erreurs) {
                if(cookie.get("portailReconnexion")) {
                    $scope.erreursParCode['01007'] = [];
                }
                cookie.remove("portailReconnexion");
                
                for (var i = 0; i < erreurs.length; i++) {
                    _pushErreur(erreurs[i]);
                }
                // Session déjà connectée...
                var erreursSession = $scope.erreursParCode['01007'];
                if (erreursSession && erreursSession.length > 0){
                    var reponse = confirm("Voulez-vous déconnecter la session en cours et tenter une reconnexion ?");
                    if (reponse) {
                        cookie.set("portailReconnexion", true);
                        // Le jeton n'est plus valide après la première vérification
                        // Il faut rejouer toute la cinématique de connexion
                        return $scope.connexionPortail(portail, cookie.get("portailIframe"));
                    }
                }
            });
        };
        
        // On interroge la description des portails dès le chargement de la page
        operisWSServer.portailsComplete()
        .then(function (portails) {
            $scope.portailsDescription = true;
            
            if(portails == null) {
                return;
            }
            
            $scope.portails = portails;
            
            // Analyse des paramètres en entrée
            var params = connexionPortail.extractUrlParams();
            
            // Si pas en déconnexion
            if (!cookie.get(LOGOUT_STATE)) {
                if (
                // Si portail unique
                (Object.keys($scope.portails).length == 1
                    // et connexion auto ou rapprochement
                    && (config.$config.portail.auto || cookie.get("rapprochement")))
                // Ou demande de connexion explicite par portail spécifique
                || params["byPortail"]
                ) {
                    if (!cookie.get("portailIframe") && !cookie.get("portailIframeError")) {
                        // Si pas dans une iframe et pas d'erreur, connexion dans une iframe
                        $scope.connexionPortail(Object.keys($scope.portails)[0], true);
                    } // Si dans une iframe ou erreur, on laisse la validation se dérouler
                }
            }
        })
        .catch(function (err) {
            $scope.portailsDescription = true;
            _pushErreur(erreur.creer(PORTAILS_DESCRIPTION_INVALIDE, null, err));
        })
        .finally(function () {
            // Analyse des paramètres de retour
            var params = connexionPortail.extractUrlParams();
            
            if(params["state"]) {
                // Désérialisation du state
                var callbackParameters = decodeURIComponent(params["state"]);
                var splitedParams = connexionPortail.splitParams(callbackParameters);
                
                if(splitedParams["state"]) {
                    // Si le state désérialisé contient un state,
                    // on remplace le state par le state après désérialisation
                    params["state"] = splitedParams["state"];
                }
            }
            
            // State Logout
            var logoutState = cookie.get(LOGOUT_STATE);
            if(logoutState && params["state"] == logoutState) {
                var href = connexionPortail.extractUrlPath() + "?" + connexionPortail.extractOriginalParams();
                $window.location.href = href;
                return;
            }
            
            var portail = cookie.get("portail");
            if (portail) {
                // On est en retour d'un portail
                if(!$scope.portails || !$scope.portails.hasOwnProperty(portail)) {
                    _pushErreur(erreur.creer(PORTAIL_UNKNOWN, null, portail));
                    _cleanupCookies();
                    return;
                }
                
                
                // Erreurs
                if(params.hasOwnProperty("error") || params.hasOwnProperty("error_description")) {
                    if (cookie.get("portailIframe")) {
                        $window.parent.postMessage("iframeError", $window.location.protocol + "//" + $window.location.host);
                    } else {
                        _pushErreur(erreur.creer(PORTAIL_AUTHORIZE_ERROR, null, params["error"], params["error_description"]));
                        _cleanupCookies();
                        return;
                    }
                }
                
                // State Login
                var state = cookie.get("portailState");
                if(!params["state"] || !state) {
                    // Pas de state en params ou en cookie, on s'arrête
                    _cleanupCookies();
                    return;
                }
                
                if(params["state"] != state) {
                    _pushErreur(erreur.creer(PORTAIL_UNVALID_STATE, null, "state"));
                    _cleanupCookies();
                    return;
                }
                
                
                var data = params["code"];  
                if($scope.portails[portail].hasOwnProperty("uidFieldSession")) {
                    data += ";" + params["session_state"];
                }
                
                if(!data) {
                    if (cookie.get("portailIframe")) {
                        $window.parent.postMessage("iframeError", $window.location.protocol + "//" + $window.location.host);
                    } else {
                        _pushErreur(erreur.creer(PORTAIL_EMPTY_ANSWER, null, portail, "code"));
                        _cleanupCookies();
                        return;
                    }
                } else {
                    if (cookie.get("portailIframe")) {
                        $window.parent.postMessage(data, $window.location.protocol + "//" + $window.location.host);
                    } else {
                        // Déclenchement de la connexion via portail
                        return _validateConnexion(data);
                    }
                }
            }
        });
    }]);