/**
 * @version 1.0
 * @copyright 2017 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Version', [])
    .service('fr.operis.moteurV4.utils.Version'
    , [ function () {
            var that = this;

            this.$version = {
              numversion:"4.14.x.0",
              numpatch:"202105201053"
            };
        }
    ]
);
