/**
 * @version 1.0
 * @copyright 2015 Operis
 * @Author Laure-H�l�ne Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.directives.OpStopEvent', [])
    .directive('opStopEvent', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                element.on(attr.opStopEvent, function (e) {
                    e.stopPropagation();
                });
            }
        };
    });

