/**
 * @version 2.0
 * @copyright 2018 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Cartographie', [])
    .service('fr.operis.moteurV4.utils.Cartographie'
        , ['$q', '$http', '$ocLazyLoad', 'fr.operis.moteurV4.utils.Config',
            function ($q, $http, $ocLazyLoad, config) {
                var moduleDefault = "Cartographie";
                var epsgDefault = "3857";
                var contextDefault = {
                    "EPSG":epsgDefault,
                    "initialExtent":[-1059137.57756,4965405.4764,1324032.37324,6774390.13396]
                };

                // Chargement du contexte
                this.chargerContexte = function (aModuleNom) {
                    var deferred = $q.defer();

                    var fileModule = config.cartoCheminContexte + aModuleNom + ".json";
                    $http.get(fileModule).then(function(responseModule) {
                        var context = responseModule.data;
                        deferred.resolve(context);
                    }, function(reason) {
                        var fileDefault = config.cartoCheminContexte + moduleDefault + ".json";
                        $http.get(fileDefault).then(function(responseDefault) {
                            var context = responseDefault.data;
                            deferred.resolve(context);
                        }, function(reason) {
                            deferred.resolve(contextDefault);
                        });
                    });
                    
                    return deferred.promise;
                };
                
                // Chargement de la projection
                this.chargerProjection = function (aEpsgCode) {
                    var deferred = $q.defer();
                    
                    $ocLazyLoad.load(['lib/proj4.min.js', 'lib/ol.min.js']).then(function() {
                        if (!angular.isNullOrUndefined(proj4.defs('EPSG:' + aEpsgCode))) {
                            deferred.resolve(aEpsgCode);
                        } else {
                            var epsgQuery = 'https://epsg.io/?format=json&q=' + aEpsgCode;
                            return $http.get(epsgQuery).then(function(response) {
                                var results = response.data['results'];
                                
                                try {
                                    var proj4def = results[0]['proj4'], bbox = results[0]['bbox'];

                                    proj4.defs('EPSG:' + aEpsgCode, proj4def);
                                    proj4.defs('urn:ogc:def:crs:EPSG:6.9:' + aEpsgCode, proj4def);

                                    var newProj = ol.proj.get('EPSG:' + aEpsgCode);
                                    var fromLonLat = ol.proj.getTransform('EPSG:4326', newProj);

                                    // very approximate calculation of projection extent
                                    var extent = ol.extent.applyTransform(
                                        [bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat);
                                    newProj.setExtent(extent);
                                    
                                    deferred.resolve(aEpsgCode);
                                } catch (err) {
                                    deferred.resolve(epsgDefault);
                                }
                            }, function(reason) {
                                deferred.resolve(epsgDefault);
                            });
                        }
                    });
                    
                    return deferred.promise;
                };
                
                // Affichage des attributs dans une table html
                this.creerTableAttributs = function (aFeature) {
                    var table, tr, th, td;
                    
                    table = document.createElement("table");
                    table.className = "table table-bordered table-condensed";
                    tr = table.insertRow(-1);
                    th = document.createElement("th");
                    th.innerHTML = "Attribut";
                    tr.appendChild(th);
                    th = document.createElement("th");
                    th.innerHTML = "Valeur";
                    tr.appendChild(th);
                    
                    var keys = aFeature.getKeys();
                    for (var i = 0; i < keys.length; i++) {
                        if (keys[i] == "geometry") continue;
                        
                        tr = table.insertRow(-1);
                        td = tr.insertCell(-1);
                        td.innerHTML = keys[i];
                        td = tr.insertCell(-1);
                        td.innerHTML = aFeature.get(keys[i]);
                    }                              
                    return table;
                }
            }
        ]
    );
