/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpConnexion', [
    'ui.bootstrap',
    'fr.operis.moteurV4.directives.OpSimpleFormat',
    'fr.operis.moteurV4.communication.OperisWSServer',
    'fr.operis.moteurV4.directives.OpAutoFocus'])
    .controller('fr.operis.moteurV4.composants.OpConnexionControleur',
    ['$scope', '$modalInstance', '$window', '$filter', 'fr.operis.moteurV4.communication.OperisWSServer', 'utilisateur', 'erreur',
        function ($scope, $modalInstance, $window, $filter, operisWSServer, utilisateur, erreur) {
            $scope.utilisateur = utilisateur;
            $scope.motdepasse = "";
            $scope.utilisateurModifiable = angular.isNullOrUndefined(utilisateur);
            $scope.erreursParCode = {};
            $scope.sessionsFermees = false;

            if (!angular.isNullOrUndefined(erreur)) {
                if (!angular.isNullOrUndefined($scope.erreursParCode[erreur.codeServeur]))
                    $scope.erreursParCode[erreur.codeServeur] = [erreur];
                else
                    $scope.erreursParCode[erreur.codeServeur].push(erreur);
            }



            $scope.pressePapier = function (erreur) {
                $window.prompt("Copier le texte : Ctrl+C, Entrée", angular.toJson(erreur));
            };

            $scope.valider = function () {
                return operisWSServer.connexionComplete($scope.utilisateur, $scope.motdepasse).then(
                    function () {
                        $modalInstance.close();
                    },
                    function (erreurs) {
                        for (var i = 0; i < erreurs.length; i++) {
                            if ($scope.erreursParCode.hasOwnProperty(erreurs[i].codeServeur))
                                $scope.erreursParCode[erreurs[i].codeServeur].push(erreurs[i]);
                            else
                            $scope.erreursParCode[erreurs[i].codeServeur] = [erreurs[i]];
                        }

                        // Session déjà connecté...
                        var erreursSession = $scope.erreursParCode['01007'];
                        if ( erreursSession && erreursSession.length > 0){
                            var reponse = confirm("Voulez-vous déconnecter la session en cours et tenter une reconnexion ?");
                            if ( reponse ){
                                return $scope.fermer().then($scope.valider);
                            }
                        }
                    });
            };

            $scope.fermer = function () {
                return operisWSServer.deconnexionComplete($scope.utilisateur, $scope.motdepasse).then(
                    function () {
                        $scope.erreursParCode['01007'] = [];
                        $scope.sessionsFermees = true;
                    },
                    function (erreur) {
                        if (!angular.isNullOrUndefined($scope.erreursParCode[erreur.codeServeur]))
                            $scope.erreursParCode[erreur.codeServeur] = [erreur];
                        else
                            $scope.erreursParCode[erreur.codeServeur].push(erreur);
                    });
            };
        }]);