/**
 * @version 1.0
 * @copyright 2016 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.popup.OpPopupMessagerie', ['fr.operis.moteurV4.utils.Messagerie'])
    // Champ identifiant du message
    .constant('fr.operis.moteurV4.composants.popup.OpPopupMessagerie.CHAMP_ID_MESSAGE', 'ID_MESSAGE')
    .controller('fr.operis.moteurV4.composants.popup.OpPopupMessagerieControleur',
        ['$scope'
            , '$modalInstance'
            , 'fr.operis.moteurV4.composants.popup.OpPopupMessagerie.CHAMP_ID_MESSAGE'
            , 'fr.operis.moteurV4.utils.Messagerie'
            , 'onglet'
            , 'selection'
            , 'onChangeEtat'
            , function ($scope
            , $modalInstance
            , CHAMP_ID_MESSAGE
            , utilsMessagerie
            , onglet
            , selection
            , onChangeEtat) {

            // Initilialisation
            $scope.descriptionRechercheMessage = {};
            $scope.donneesRechercheMessage = [];
            $scope.selectionRechercheMessage = [];
            $scope.contenuMessage = "";

            // Chargement de la grille des messages
            if (onglet && selection)
                utilsMessagerie.rechercheMessagesEntites('Operia', onglet, selection, $scope);
            else
                utilsMessagerie.rechercheMessagesUtilisateur('Operia', $scope);


            // Affichage du contenu du message
            $scope.afficherContenu = function (nomModule, nomEcran, nomsIdentifiants, entiteRecherche) {
                $scope.contenuMessage = entiteRecherche[nomsIdentifiants].valeur;

                // Mise à jour de l'état du message à lu
                utilsMessagerie.etatMessage('Operia', entiteRecherche[CHAMP_ID_MESSAGE].valeur, 'Lu')
                    .then(function () {
                        // Dispatch l'événement de changement d'état
                        onChangeEtat();
                    });
            };

            // Mise à jour de l'état du message vers non lu
            $scope.marquernonlu = function () {
                for (var i = 0; i < $scope.selectionRechercheMessage.length; i++) {
                    var idMessage = $scope.selectionRechercheMessage[i][CHAMP_ID_MESSAGE].valeur;

                    // Mise à jour de l'état du message à non lu
                    utilsMessagerie.etatMessage('Operia', idMessage, 'Non lu')
                        .then(function () {
                            // Dispatch l'événement de changement d'état
                            onChangeEtat();
                        });
                }
            };

            // Fermeture de la popup
            $scope.fermer = function () {
                $modalInstance.dismiss();
            };
        }]);