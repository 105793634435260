/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

/**
 *  Filtre pour trier.
 */
angular.module('fr.operis.moteurV4.filtres.OpOrderBy', [])
    .filter('opOrderBy', ['orderByFilter', function (orderByFilter) {

        return function (array, expression, reverse) {
            var opExpression= function(valeur) {
                return valeur[expression].valeur;
            }
            return orderByFilter(array, opExpression, reverse);
        };
    }]);