/**
 * @version 1.0
 * @copyright 2018 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Cookie', ['ipCookie'])
    .service('fr.operis.moteurV4.utils.Cookie'
        , ['$browser', '$document', '$window', 'ipCookie',
            function ($browser, $document, $window, ipCookie) {
            var currentUrlPath = null;
            /** 
             * Options par défaut des cookies, commenter encode et decode pour un comportement par défaut
             * @return {object} Les options par défaut.
             *  Voici un exemple d'options exaustif :
             *  {
             *      'path'           : 'http://localhost:8080/gnau/Application/gnau.html',
             *      'encode'         : function (value) { return value; }, // Créer un bug, les éléments ne sont plus reconnus. Pour résoudre le problème, ces méthodes seront éxécutées en internes.
             *      'decode'         : function (value) { return value; }, // Créer un bug, les éléments ne sont plus reconnus. Pour résoudre le problème, ces méthodes seront éxécutées en internes.
             *      'secure'         : false,
             *      'expires'        : 21,
             *      'expirationUnit' : 'minutes' // Peut prendre les valeurs suivantes : 'hours', 'minutes', 'seconds', ou 'milliseconds'. Si non spécifié, ipCookie interprétera 'expires' en jours.
             *   }
             *
             */
            function _defaultOptions () {
                return { 
                    'path'   : _extractUrlPath(),
                    'encode' : _defaultEncode,
                    'decode' : _defaultDecode
                };
            }
            
            /**
             * Encodage de la valeur à sauvegardée.
             * @param {string} data La valeur stringifier.
             * @return {string} La valeur encodée.
             */
            function _defaultEncode(data) {
                if(!angular.isNullOrUndefined(data)) {
                    try {
                        data = $window.btoa(data);
                    } catch(e) {
                        console.log("Un erreur est survenue lors de l'encodage du paramètre, il ne sera pas encodé. " + e);
                    }
                }
                
                return data;
            }
            
            /**
             * Décodage de la valeur à sauvegardée.
             * @param {string} data La valeur stringifier.
             * @return {string} La valeur décodée.
             */
            function _defaultDecode(data) {
                if(!angular.isNullOrUndefined(data) && typeof data === 'string') {
                    try {
                        data = $window.atob(data);
                    } catch(e) {
                        console.log("Un erreur est survenue lors du décodage du paramètre, il ne sera pas décodé. " + e);
                    }
                }
                
                return data;
            }
            
            /**
             * Récupération de l'URL courante.
             * @return {string} L'URL courante.
             */
            function _extractUrlPath() {
                if(currentUrlPath == null) {
                    currentUrlPath = $window.location.href;
                    
                    if(currentUrlPath.indexOf("#") > -1)
                        currentUrlPath = currentUrlPath.substring(0, currentUrlPath.indexOf("#"));
                    if(currentUrlPath.indexOf("?") > -1)
                        currentUrlPath = currentUrlPath.substring(0, currentUrlPath.indexOf("?"));
                }
                
                return currentUrlPath;
            }
            
            /**
             * Concaténation des options par défaut avec celles passées en paramètres.
             * @param {object} aOptions Les options supplémentaires à appliquées. Pour utiliser la valeur par défaut de ipCookie pour une option donnée, initialisée la clé avec undefined ou null.
             * @return {object} Les options misent à jour.
             */
            function _applyOptions(aOptions) {
                var options = _defaultOptions();
                
                if(!angular.isNullOrUndefined(aOptions)) {
                    keys = Object.keys(aOptions);
                    for(i = 0 ; i < keys.length ; ++i) {
                        if(angular.isNullOrUndefined(aOptions[keys[i]]) && options.hasOwnProperty(keys[i]))
                            delete options[keys[i]];
                        else if(!angular.isNullOrUndefined(aOptions[keys[i]]))
                            options[keys[i]] = aOptions[keys[i]];
                    }
                }
                
                return options;
            }
            
            /**
             * Récupération de la valeur du cookie (avec desobfuscation).
             * @param {string} nom Le nom du cookie.
             * @param {object} aOptions Les options supplémentaires à appliquées au cookie, optionnel.
             * @returns {string} la valeur du cookie
             */
            this.get = function (nom, aOptions) {
                var decodeFunc = null;
                var options = _applyOptions(aOptions);
                
                decodeFunc = options['decode'];
                
                delete options['encode'];
                delete options['decode'];
                
                var ret = null;
                if ((angular.isNullOrUndefined($document) || $document.length == 0 || angular.isNullOrUndefined($document[0].documentMode)) && !$window.StyleMedia) {
                    ret = ipCookie(nom, undefined, options);
                } else { // IE
                    var path = "/";
                    
                    // Path
                    if(options.hasOwnProperty("path") && !angular.isNullOrUndefined(options.path) && options.path.length > 0)
                        path = options.path;
                    
                    // On concatène le path avec le nom du cookie pour IE car le get ne fonctionne pas en multi-domaine
                    ret = $browser.cookies()[path + nom];
                }
                
                if(!angular.isNullOrUndefined(decodeFunc)) {
                    ret = decodeFunc(ret);
                }
                
                try {
                    ret = JSON.parse(ret);
                } catch (e) {}
                
                return ret;
            };

            /**
             * Mise en place du cookie (avec obfuscation).
             * @param {string} nom Le nom du cookie.
             * @param {string} value La valeur du cookie
             * @param {object} aOptions Les options supplémentaires à appliquées au cookie, optionnel.
             */
            this.set = function (nom, value, aOptions) {
                var encodeFunc = null;
                var options = _applyOptions(aOptions);
                
                encodeFunc = options['encode'];
                
                delete options['encode'];
                delete options['decode'];
                
                var encodedVal = value;
                
                try {
                    encodedVal = JSON.stringify(encodedVal);
                } catch (e) {}
                
                if(!angular.isNullOrUndefined(encodeFunc)) {
                    encodedVal = encodeFunc(encodedVal);
                }
                
                if ((angular.isNullOrUndefined($document) || $document.length == 0 || angular.isNullOrUndefined($document[0].documentMode)) && !$window.StyleMedia) {
                    ipCookie(nom, encodedVal, options);
                } else { // IE
                    var date = null;
                    var path = "/";
                    
                    // Path
                    if(options.hasOwnProperty("path") && !angular.isNullOrUndefined(options.path) && options.path.length > 0)
                        path = options.path;
                        
                    
                    // Expires
                    if(options.hasOwnProperty("expires") && !angular.isNullOrUndefined(options.expires) && !isNaN(options.expires)) {
                        if(typeof options.expires == 'string')
                            options.expires = parseInt(options.expires);
                        
                        var expirationUnit = "days";
                        var coeff = 0;
                        date = new Date();
                        if(options.hasOwnProperty("expirationUnit") && !angular.isNullOrUndefined(options.expirationUnit) && options.expirationUnit.length > 0)
                            expirationUnit = options.expirationUnit;
                        
                        if(expirationUnit == "hours") {
                            coeff = 60 * 60 * 1000;
                        } else if (expirationUnit == "minutes") {
                            coeff = 60 * 1000;
                        } else if (expirationUnit == "seconds") {
                            coeff = 1000;
                        } else if (expirationUnit == "milliseconds") {
                            coeff = 1;
                        } else {
                            // Days
                            coeff = 24 * 60 * 60 * 1000;
                        }
                        
                        date.setTime(date.getTime() + (options.expires * coeff));
                    }
                    
                    // On concatène le path avec le nom du cookie pour IE car le get ne fonctionne pas en multi-domaine
                    var cookie = path + nom + "=" + encodedVal + ';path=/;';
                    if (date)
                        cookie += ' expires=' + date.toUTCString();
                     
                    $document[0].cookie = cookie;
                }
            };

            /**
             * Suppression du cookie.
             * @param {string} nom Le nom du cookie.
             * @param {object} aOptions Les options supplémentaires à appliquées au cookie, optionnel.
             */
            this.remove = function (nom, aOptions) {
                var options = _applyOptions(aOptions);
                
                // Expiration du cookie
                options['expires'] = 1;
                options['expirationUnit'] = 'milliseconds';
                
                delete options['encode'];
                delete options['decode'];
                
                if ((angular.isNullOrUndefined($document) || $document.length == 0 || angular.isNullOrUndefined($document[0].documentMode)) && !$window.StyleMedia) {
                    ipCookie(nom, "", options);
                } else {// IE
                    var path = "/";
                    
                    // Path
                    if(options.hasOwnProperty("path") && !angular.isNullOrUndefined(options.path) && options.path.length > 0)
                        path = options.path;
                    
                    // On concatène le path avec le nom du cookie pour IE car le get ne fonctionne pas en multi-domaine
                    $document[0].cookie = path + nom + '=; path=/; expires=' + new Date(0).toUTCString();
                }
            };
        }
    ]);