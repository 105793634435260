/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Brian Kjerulf, Operis
 */

'use strict';
angular.module('fr.operis.moteurV4.composants.OpChartBar', [])
    .directive('opChartBar', ['$compile', '$timeout', function ($compile, $timeout) {
            return {
                restrict: 'E',
                transclude: false,
                scope:{
                    opChartData: '=',
                    opChartOptions: '=',
                    opChartLegend: '=',
                    opTitre:'@' ,
                    opClick:'&'
                },
                template: ''+
                    '<div class="container-fluid" oc-lazy-load="[\'lib/Chart.min.js\', \'lib/tc-angular-chartjs.min.js\']">'+
                        '<div class="panel panel-default">'+
                            '<div class="panel-heading">'+
                                '<div class="row">'+
                                    '<strong style="padding-left: 10px">{{opTitre}}</strong>'+
                                '</div>'+
                            '</div>'+
                            '<div class="panel-body" style="overflow: hidden">'+
                                '<canvas tc-chartjs-bar chart="chart" style="padding-right: 20px" chart-options="opChartOptions" chart-data="opChartData" chart-legend="opChartLegend"></canvas>'+
                                '<div tc-chartjs-legend chart-legend="opChartLegend"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>',
                    
                link: function postLink(scope, iElement, iAttrs, controller) {
                  var cElt = iElement.children(),
                    width = 0,
                    height = 0;
                  function verifTaille() {
                    if(width !== cElt.prop('offsetWidth') || height !== cElt.prop('offsetHeight')) {
                      // On d�clenche un cycle de digest pour rentrer dans le watch
                      scope.$digest();
                    } else {
                      $timeout(verifTaille, 500, false);
                    }
                  }
                  $timeout(verifTaille, 500, false);

                  scope.$watch(function() {
                    return cElt.prop('offsetWidth') + ';' + cElt.prop('offsetHeight');
                  },
                  function(value) {
                    if(scope.chart && value !== '0;0' && isNaN(scope.chart.chart.aspectRatio)) {
                      scope.chart.chart.aspectRatio = 2;
                      scope.chart.resize(function () {

                        for (var datasetIndex = 0; datasetIndex < scope.chart.datasets.length; datasetIndex++) {
                          for (var barIndex = 0; barIndex < scope.chart.datasets[datasetIndex].bars.length; barIndex++) {
                            scope.chart.datasets[datasetIndex].bars[barIndex].y = scope.chart.datasets[datasetIndex].bars[barIndex].y || 0;
                          }
                        }
                        scope.chart.reflow();
                        scope.chart.update();
                        scope.chart.draw();
                      });
                    }
                  });
                }
            }
        }]
    );