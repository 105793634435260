/**
 * @name OpPopupAvancementTache
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAvancementTache', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAvancementTacheControleur', ['$scope',
        '$modalInstance', 'vueFormulaire','$timeout', function (scope, modalInstance, vueFormulaire, $timeout) {
            scope.vueFormulaire = {
                nomModule:"Intervention",
                nomEcran:"TAC_MODIFIERAVANCEMENT",
                identifiant: {"ID_TACHE":""}
            };

            scope.finChargementFormulaire = function (aScope){
                scope.$scopeFormulaire = aScope;

                var tache = vueFormulaire.tache;
                if (!angular.isNullOrUndefined(tache.data) && !angular.isNullOrUndefined(tache.data.element)) {
                    scope.$scopeFormulaire.champs.TACHE.TAC_OBJET.valeur = tache.data.element.libLigne;
                    scope.$scopeFormulaire.champs.TACHE.TAC_DESCRIPTION.valeur = tache.data.element.descLigne;

                    if (!angular.isNullOrUndefined(tache.data.element.debut)) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_DATEDEBUT_PREV.valeur = moment(tache.data.element.debut).toDate();
                    }

                    if (!angular.isNullOrUndefined(tache.data.element.fin)) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_DATEFIN_PREV.valeur = moment(tache.data.element.fin).toDate();
                    }

                    if (!angular.isNullOrUndefined(tache.data.element.debutReel)) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_DATEDEBUT.valeur = moment(tache.data.element.debutReel).toDate();
                    }

                    if (!angular.isNullOrUndefined(tache.data.element.finReelle)) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_DATEFIN.valeur = moment(tache.data.element.finReelle).toDate();
                    }

                    // Formattage de l'heure de début et de fin
                    /*if (angular.isNullOrUndefined(tache.data.element.heureDebut) || tache.data.element.heureDebut.length == 0) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_HEUREDEBUT.valeur = null;
                    } else {
                        scope.$scopeFormulaire.champs.TACHE.TAC_HEUREDEBUT.valeur = tache.data.element.heureDebut;
                    }

                    if (angular.isNullOrUndefined(tache.data.element.heureFin) || tache.data.element.heureFin.length == 0) {
                        scope.$scopeFormulaire.champs.TACHE.TAC_HEUREFIN.valeur = null;
                    } else {
                        scope.$scopeFormulaire.champs.TACHE.TAC_HEUREFIN.valeur = tache.data.element.heureFin;
                    }*/

                    scope.$scopeFormulaire.champs.TACHE.TAC_DUREEJOUR.valeur = tache.data.element.dureeJours;
                    scope.$scopeFormulaire.champs.TACHE.TAC_DUREEHEURE.valeur = tache.data.element.dureeHeures;
                    scope.$scopeFormulaire.champs.TACHE.TAC_AVANCEMENT.valeur = tache.data.element.avancement;
                    scope.$scopeFormulaire.champs.TAC_STATUT.TAC_STA_NOM.valeur = tache.data.element.statutLigne;
                }

                // Ecouteur sur le statut pour marquer l'avancement (Si statut terminé ou annulé, on met l'avancement à 100%)
                scope.$watch('$scopeFormulaire.champs.TAC_STATUT.TAC_STA_NOM.valeur', function(value){
                    if (!angular.isNullOrUndefined(value) && value.length>0 && ((value === "Terminée") || (value === "Annulée"))){
                        scope.$scopeFormulaire.champs.TACHE.TAC_AVANCEMENT.valeur = 100;
                        $timeout(function () {
                            scope.$broadcast('rzSliderForceRender');
                        });
                    } else {
                        scope.$scopeFormulaire.champs.TACHE.TAC_AVANCEMENT.valeur = 0;
                    }
                })
            };

            /**
             * Valder les modifications et fermer l'écran
             */
            scope.valider = function () {
                var tache = {};

                tache.debutReel = scope.$scopeFormulaire.champs.TACHE.TAC_DATEDEBUT.valeur;
                tache.finReelle = scope.$scopeFormulaire.champs.TACHE.TAC_DATEFIN.valeur;
                //tache.heureDebut = scope.$scopeFormulaire.champs.TACHE.TAC_HEUREDEBUT.valeur;
                //tache.heureFin = scope.$scopeFormulaire.champs.TACHE.TAC_HEUREFIN.valeur;
                tache.dureeJours = scope.$scopeFormulaire.champs.TACHE.TAC_DUREEJOUR.valeur;
                tache.dureeHeures = scope.$scopeFormulaire.champs.TACHE.TAC_DUREEHEURE.valeur;
                tache.avancement = scope.$scopeFormulaire.champs.TACHE.TAC_AVANCEMENT.valeur;
                tache.statutLigne = scope.$scopeFormulaire.champs.TAC_STATUT.TAC_STA_NOM.valeur;

                modalInstance.close(tache);
            };

            /**
             * Annuler
             */
            scope.fermer = function () {
                modalInstance.close();
            };

            /**
             * Marquer la tâche comme terminée
             */
            scope.terminer = function (){
                scope.$scopeFormulaire.champs.TAC_STATUT.TAC_STA_NOM.valeur = "Terminée";
                scope.valider();
            };
        }]
    );

