'use strict';

angular.module('operiaChat.chat')

.directive('operiaChat', ['$ocLazyLoad', '$http', function($ocLazyLoad, $http, operiaConfig) {
  return {
    scope: {
      start: '='
    },

    controller: ['$scope', 'operiaConfig', function($scope, operiaConfig) {

      $ocLazyLoad.load({
          serie: true,
          files: [
            'lib/adapter-latest.min.js'
          ]
        })
        .then(function() {
          //Init members
          $scope.active = false;
          $scope.users = [];
          $scope.chats = [];
          $scope.userList = {};
          //TODO change to true
          $scope.userList.minimized = false;

          $scope.webRtcCompliant = (navigator.getUserMedia !== undefined);

          /**
           * Method to start chat and connect to server
           */
          $scope.start = function(login) {
            $scope.login = login;
            //let's login to
            $http({
              method: 'GET',
              url: operiaConfig.urlHttp + 'connection?login=' + login + "&webRtcCompliant=" + $scope.webRtcCompliant
            }).then(function successCallback(response) {
              $scope.connected = true;
              $scope.refreshUserList();
              $scope.websocket = new WebSocket(operiaConfig.urlWs + 'chat');
              $scope.websocket.onopen = function(evt) {
                $scope.onOpen(evt);
              };
              $scope.websocket.onclose = function(evt) {
                $scope.onClose(evt);
              };
              $scope.websocket.onmessage = function(evt) {
                $scope.onMessage(evt);
              };
              $scope.websocket.onerror = function(evt) {
                $scope.onError(evt);
              };
            }, function errorCallback(response) {
              $scope.connected = false;
              $scope.wsConnected = false;
            });

            /**
             * Method to send message to user identified by uuid
             */
            $scope.sendMessage = function(event, uuid, message) {
              if (message === "") return;
              var code = event.which || event.keyCode;
              if (code == 13) {
                //Try to retrieve the element of message list
                var alreadyInChat = false;
                $scope.chats.forEach(function(element) {
                  if (element.uuid == uuid) {
                    if (element.ul === undefined)
                      element.ul = event.target.parentNode.parentNode.children[1];
                  }
                }, this);
                //Will send message
                if ($scope.wsConnected) {
                  var jsonToSend = {
                    "action": "chat",
                    "to": uuid,
                    "message": message
                  };
                  $scope.websocket.send(JSON.stringify(jsonToSend));
                } else {
                  alert("Vous n'êtes pas conecté !");
                }
              }
            };

            /**
             * Method to open a blank page to make videochat
             */
            $scope.videoChat = function(uuid) {
              var token = Date.now() + "-" + Math.round(Math.random() * 10000);
              localStorage.setItem("caller", "true");
              localStorage.setItem("to", uuid);
              localStorage.setItem("callId", token);
              if (operiaConfig.isMobile)
                window.open("videochat.html", 'Video Chat');
              else
                window.open("videochat.html", 'Video Chat', 'resizable=no, location=no, width=1024, height=768, menubar=no, status=no, scrollbars=no, menubar=no');
              var jsonToSend = {
                "action": "chat",
                "to": uuid,
                "message": "VIDEO-" + token
              };
              $scope.websocket.send(JSON.stringify(jsonToSend));
            };

            /**
             * Method to open a blank page to make videochat
             */
            $scope.answerVideoChat = function(msg) {
              localStorage.setItem("caller", "false");
              localStorage.setItem("callId", msg.text);
              if (operiaConfig.isMobile)
                window.open("videochat.html", 'Video Chat');
              else
                window.open("videochat.html", 'Video Chat', 'resizable=no, location=no, width=1024, height=768, menubar=no, status=no, scrollbars=no, menubar=no');
              msg.state = 1;
              var jsonToSend = {
                "action": "chat",
                "to": msg.from,
                "message": "ACK-" + msg.text
              };
              $scope.websocket.send(JSON.stringify(jsonToSend));
            };


            /**
             * Method to reject incomming call
             */
            $scope.hangupVideoChat = function(msg) {
              msg.state = 2;
              var jsonToSend = {
                "action": "chat",
                "to": msg.from,
                "message": "NACK-" + msg.text
              };
              $scope.websocket.send(JSON.stringify(jsonToSend));
            };


            /**
             * Fired on WS connection
             */
            $scope.onOpen = function(evt) {
              $scope.wsConnected = true;
            };

            /**
             * Fired on WS deconnection
             */
            $scope.onClose = function(evt) {
              $scope.wsConnected = false;
              $scope.chats.forEach(function(element) {
                element.isOnline = false;
              }, this);
              $scope.$apply();
              //Try to reconnect
              $scope.start($scope.login);
            };

            /**
             * Fired on incomming WebSocket Message
             */
            $scope.onMessage = function(evt) {
              var json = JSON.parse(evt.data);
              if (json.action == "USR") {
                //Connection or disconnection from another user, let's refresh list
                $scope.refreshUserList();
              } else if (json.action == "CHAT-RECV") {
                var alreadyInChat = false;
                var message = "";
                if (json.message.indexOf("VIDEO") === 0) {
                  message = {
                    "sended": false,
                    "video": true,
                    "from": json.from,
                    "state": 0,
                    "text": json.message.replace("VIDEO-", "")
                  };
                } else if (json.message.indexOf("ACK") === 0 || json.message.indexOf("NACK") === 0) {
                  //Aknowleegement of video ask
                  $scope.chats.forEach(function(element) {
                    if (element.uuid == json.from) {
                      element.messages[element.messages.length - 1].state = json.message.indexOf("ACK") === 0 ? 1 : 2;
                    }
                  }, this);
                  $scope.$apply();
                  return;
                } else
                  message = {
                    "sended": false,
                    "video": false,
                    "from": json.from,
                    "text": json.message
                  };
                $scope.chats.forEach(function(element) {
                  if (element.uuid == json.from) {
                    element.isOnline = true;
                    element.minimized = false;
                    alreadyInChat = true;
                    //Add message to chat
                    element.messages.push(message);
                    $scope.$apply();
                    if (element.ul !== undefined)
                      element.ul.scrollTop = element.ul.scrollHeight;
                  } else
                  if (operiaConfig.isMobile)
                    element.minimized = true;
                }, this);
                if (!alreadyInChat) {
                  $scope.chats.push({
                    "uuid": json.from,
                    "minimized": false,
                    "isOnline": true,
                    "login": json.fromLogin,
                    "webrtcCompliant": $scope.isUserWebRtcCompliant(json.from),
                    "message": "",
                    "messages": [message]
                  });
                  $scope.userList.minimized = false;
                  $scope.$apply();
                }
              } else if (json.action == "CHAT-SEND") {
                //The message I've sent and that have been delivered
                var message = "";
                if (json.message.indexOf("ACK") === 0 || json.message.indexOf("NACK") === 0) return;
                if (json.message.indexOf("VIDEO") === 0) {
                  message = {
                    "sended": true,
                    "video": true,
                    "state": 0,
                    "text": json.message
                  };
                } else
                  message = {
                    "sended": true,
                    "video": false,
                    "text": json.message
                  };

                var alreadyInChat = false;
                $scope.chats.forEach(function(element) {
                  if (element.uuid == json.to) {
                    alreadyInChat = true;
                    //Add message to chat
                    element.messages.push(message);
                    element.minimized = false;
                    element.isOnline = true;
                    element.message = "";
                    $scope.$apply();
                    if (element.ul !== undefined)
                      element.ul.scrollTop = element.ul.scrollHeight;
                  }
                }, this);
                if (!alreadyInChat) {
                  $scope.chats.push({
                    "uuid": json.to,
                    "minimized": false,
                    "login": json.toLogin,
                    "message": "",
                    "isOnline": true,
                    "messages": [message]
                  });
                  $scope.$apply();
                }
              }

            };

            /**
             * Returns if user identified by uuid is WebRtcCompliant
             */
            $scope.isUserWebRtcCompliant = function(uuid) {
              var webrtcCompliant = false;
              $scope.users.forEach(function(element) {
                if (element.uuid == uuid)
                  webrtcCompliant = element.webrtcCompliant;
              }, this);
              return webrtcCompliant;

            };

            /**
             * Fired on error on the WS
             */
            $scope.onError = function(evt) {
              $scope.wsConnected = false;
            };

            //Show windows
            $scope.active = true;

          };

          /**
           * Method to retrieve conected user list
           */
          $scope.refreshUserList = function() {
            $http({
              method: 'GET',
              url: operiaConfig.urlHttp + 'userList'
            }).then(function successCallback(response) {
              $scope.users = response.data.users;
              $scope.chats.forEach(function(chat) {
                chat.isOnline = false;
                $scope.users.forEach(function(user) {
                  if (chat.uuid == user.uuid)
                    chat.isOnline = true;
                }, this);
              }, this);
            }, function errorCallback(response) {
              $scope.users = [];
            });
          };

          /**
           * Mehod to initiate a chat with another user
           */
          $scope.chatWith = function(uuid, login) {
            var alreadyInChat = false;
            $scope.chats.forEach(function(element) {
              if (element.uuid == uuid) {
                alreadyInChat = true;
                element.minimized = !element.minimized;
              } else
              if (operiaConfig.isMobile)
                element.minimized = true;
            }, this);
            if (!alreadyInChat)
              $scope.chats.push({
                "uuid": uuid,
                "login": login,
                "webrtcCompliant": $scope.isUserWebRtcCompliant(uuid),
                "message": "",
                "messages": [],
                "minimized": false
              });
          };

          /**
           * Method to minimize, maximize user list
           */
          $scope.toggleList = function() {
            $scope.userList.minimized = !$scope.userList.minimized;
          };

          /**
           * Method to minimize, maximize chat with user uuid
           */
          $scope.toggle = function(uuid) {
            $scope.chats.forEach(function(element) {
              if (element.uuid == uuid)
                element.minimized = !element.minimized;
            }, this);
          };

          /**
           * Method to hide chat
           */
          $scope.hide = function() {
            operiaConfig.affichage = false;
          };
        });

    }],
    // templateUrl: './components/operiaChat/operiaChat.html'
    templateUrl: 'moteur/composants/operiaChat/operiaChat.html'
  };
}]);